import './Skills.css';

import Skill from './Skill';
import WhoAmI from './WhoAmI';

const Skills = () => {
  const skills = [
    {
      name: 'HTML',
      percentage: 90,
    },
    {
      name: 'CSS/SASS',
      percentage: 95,
    },
    {
      name: 'JavaScript',
      percentage: 90,
    },
    {
      name: 'React',
      percentage: 85,
    },
    {
      name: 'Firebase',
      percentage: 80,
    },
    {
      name: 'Photoshop',
      percentage: 95,
    },
    {
      name: 'Illustrator',
      percentage: 90,
    },
    {
      name: 'Adobe XD',
      percentage: 50,
    },
  ];

  return (
    <div className='skills'>
      <WhoAmI />
      <div className='skills_container' id='skills_container'>
        {skills.map(({ name, percentage }) => (
          <Skill name={name} percentage={percentage} key={name} />
        ))}
      </div>
    </div>
  );
};

export default Skills;

import './Blog.css';

import { useEffect, useState } from 'react';

import BeatLoader from 'react-spinners/BeatLoader';
import { Link } from 'react-router-dom';
import client from '../../client';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  // A function that changes string from 2021-09-30 to 30.09.2021
  const formatDate = (date) => {
    const dateArray = date.split('-');
    const newDate = `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
    return newDate;
  };

  useEffect(() => {
    document.title = 'Blog | Omar AbdulRahman';
    client
      .fetch(
        `*[_type == "post"]{
          title,
          subtitle,
          "slug": slug.current,
          "date": publishedAt,
          body,
          mainImage{
            asset->{
              url
            }
          },
          "author": author->{
            name
          },
          categories[]->{
            title
          }
        }`
      )
      .then((data) => {
        setPosts(data.sort((a, b) => new Date(b.date) - new Date(a.date)));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <div className='loading'>
          <BeatLoader size={15} sizeUnit={'px'} margin={5} color={'#E4B05E'} />
        </div>
      ) : (
        <div className='posts'>
          {posts.map((post) => (
            <article className='post' key={post.slug}>
              <Link to={`/blog/${post.slug}`}>
                <div className='tags'>
                  {post.categories.map(({ title }) => (
                    <span className='post_category' key={title}>
                      {title}
                    </span>
                  ))}
                </div>
                <img src={post.mainImage.asset.url} alt={post.title} />
                <p className='post_date'>
                  {formatDate(post.date.slice(0, 10))}
                </p>
                <h4 className='post_title_small'>{post.title}</h4>
                <p className='post_subtitle_small'>{post.subtitle}</p>
              </Link>
            </article>
          ))}
        </div>
      )}
    </div>
  );
};

export default Blog;

import { Link } from 'react-router-dom';

import logo from '../assets/logo.png';

import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <div className='footer_content'>
        <p>
          &copy; {new Date().getFullYear()} Omar AbdulRahman - All Rights
          Reserved
        </p>
        <div className='links'>
          <a
            title='GitHub'
            target='_blank'
            rel='noreferrer'
            href='https://github.com/omarr45/'>
            <i className='fab fa-github' />
          </a>
          <a
            title='YouTube'
            target='_blank'
            rel='noreferrer'
            href='https://www.youtube.com/OmarAbdulRahman45'>
            <i className='fab fa-youtube' />
          </a>
          <a
            title='LinkedIn'
            target='_blank'
            rel='noreferrer'
            href='https://www.linkedin.com/in/omar45/'>
            <i className='fab fa-linkedin' />
          </a>
          <a
            title='Wuzzuf'
            target='_blank'
            rel='noreferrer'
            href='https://wuzzuf.net/me/Omar45'>
            <i className='fab fa-weebly' />
          </a>
          <a title='Email' href='mailto:contact@omar45.com'>
            <i className='fas fa-envelope'></i>
          </a>
        </div>
        <Link to='/'>
          <img src={logo} alt='logo' />
        </Link>
      </div>
    </footer>
  );
};

export default Footer;

const Skill = ({ name, percentage }) => {
  return (
    <div className='skill'>
      <h4>{name}</h4>
      <div className='skill_bar'>
        <div
          className='skill_bar_percentage'
          style={{ width: percentage - 3 + '%' }}>
          {percentage}%
        </div>
      </div>
    </div>
  );
};

export default Skill;

import Me from '../Me';
import Skills from '../Skills';
import { useEffect } from 'react';

const Home = () => {
  useEffect(() => {
    document.title = 'Omar AbdulRahman';
  }, []);

  return (
    <>
      <Me />
      <Skills />
    </>
  );
};

export default Home;

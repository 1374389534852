import './ContactMe.css';

import { useEffect } from 'react';

const ContactMe = () => {
  useEffect(() => {
    document.title = 'Contact Me | Omar AbdulRahman';
  }, []);

  return (
    <div className='form_wrapper'>
      <h2 className='contact_me'>Contact Me</h2>
      <form
        className='contact_form'
        action='https://formsubmit.co/9ce408c4b940fdfeb9a4006402bf5858'
        method='POST'>
        <label className='form_label'>Name</label>
        <input
          required
          className='form_input'
          type='text'
          name='name'
          placeholder='Your Name'
        />
        <label className='form_label'>Email</label>
        <input
          required
          className='form_input'
          type='email'
          name='email'
          placeholder='Your Email'
        />
        <label className='form_label'>Message</label>
        <textarea
          required
          className='form_input'
          name='message'
          placeholder='Your Message'
        />
        <input className='form_button' type='submit' value='Send' />
      </form>
    </div>
  );
};

export default ContactMe;

import './WhoAmI.css';

import { Link } from 'react-router-dom';
import profile from '../assets/profile.png';

const WhoAmI = ({ num = 3 }) => {
  return (
    <div className='who_am_i' id='who_am_i'>
      <div className='image_wrapper'>
        <img className='profile_picture' src={profile} alt='me' />
      </div>
      <h2 id='who'>Who am I?</h2>
      <p id='about_me'>
        I'm a front end web developer, based in Cairo, Egypt.
        <br />
        I have serious passion about art, design, web development, and
        programming in general.
        <br />
        <Link className='link' to='/contact'>
          Let's make something cool!
        </Link>
      </p>
    </div>
  );
};

export default WhoAmI;

import './Navbar.css';

import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const handleClick = () => setClicked(!clicked);
  const closeMobileMenu = () => setClicked(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollY(window.scrollY);
    });
  }, []);

  return (
    <div className={`navbar ${scrollY > 90 ? 'not_at_top' : ''}`}>
      <div className='navbar-container'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <img src={logo} alt='logo' />
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
        <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-link' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/projects' className='nav-link' onClick={closeMobileMenu}>
              Projects
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/blog' className='nav-link' onClick={closeMobileMenu}>
              Blog
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/contact' className='nav-link' onClick={closeMobileMenu}>
              Contact Me
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;

import 'firebase/firestore';

import firebase from 'firebase/app';

const config = {
  apiKey: 'AIzaSyBVuAikp8pBnk2QWLNaFCNQwc1PqTQnybs',
  authDomain: 'omar45-15247.firebaseapp.com',
  projectId: 'omar45-15247',
  storageBucket: 'omar45-15247.appspot.com',
  messagingSenderId: '1046188568768',
  appId: '1:1046188568768:web:180b31338cf7bd44493d25',
  measurementId: 'G-NV7BMEH8EG',
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;

import { Typewriter } from 'react-simple-typewriter';

import logo from '../assets/logo.png';
import pdf from '../assets/OmarAbdulRahmanCV.pdf';

import './Me.css';

// import 'react-simple-typewriter/dist/index.css';

const Me = () => {
  const handleClick = () => {
    const titleElement = document.getElementById('who_am_i');
    titleElement.scrollIntoView({ behavior: 'smooth' });
  };

  const downloadCV = () => {
    const link = document.createElement('a');
    link.href = pdf;
    link.download = 'Omar AbdulRahman';
    link.click();
  };

  return (
    <div className='me'>
      <h1 className='hello'>Hi, I'm Omar</h1>
      <h1 className='hello hello2'>
        A &nbsp;
        <Typewriter
          loop
          cursor
          cursorStyle='|'
          typeSpeed={70}
          deleteSpeed={50}
          delaySpeed={2000}
          words={[
            'Web Developer',
            'UI/UX Designer',
            'Calligrapher',
            'Youtuber',
            'Nerd',
          ]}
        />
      </h1>
      {/* <p className='also'>
        I'm also a calligrapher and a designer, I have serious passion for art
      </p> */}
      <div className='buttons'>
        <button className='scroll_button' onClick={downloadCV}>
          Download CV <i className='fas fa-download'></i>
        </button>
        <button className='scroll_button animation' onClick={handleClick}>
          Scroll for more <i className='fas fa-arrow-down'></i>
        </button>
      </div>
      <img src={logo} alt='logo' className='hovering' />
    </div>
  );
};

export default Me;

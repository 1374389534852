import './App.css';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Blog from './components/pages/Blog';
import ContactMe from './components/pages/ContactMe';
import Footer from './components/Footer';
import Home from './components/pages/Home';
import Navbar from './components/Navbar';
import Projects from './components/pages/Projects';
import SinglePost from './components/pages/SinglePost';

function App() {
  return (
    <div className='App'>
      <Router>
        <Navbar />
        <div className='content'>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/projects' exact component={Projects} />
            <Route path='/blog/:slug' component={SinglePost} />
            <Route path='/blog' component={Blog} />
            <Route path='/contact' exact component={ContactMe} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

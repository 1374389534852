import './SinglePost.css';

import { useEffect, useState } from 'react';

import BeatLoader from 'react-spinners/BeatLoader';
import BlockContent from '@sanity/block-content-to-react';
import Disqus from 'disqus-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import client from '../../client';
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useParams } from 'react-router';

const replaceLastDot = (str) => {
  const firstDash = str.indexOf('-');
  const lastDash = str.lastIndexOf('-');
  const suffix = str.substring(lastDash);
  return str.substring(firstDash + 1, lastDash) + '.' + suffix.substring(1);
};

const SinglePost = () => {
  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();

  const arabicChars = /[\u0600-\u06FF\u0750-\u077F]/;
  const disqusShortname = 'omarr45';

  const serializers = {
    marks: {
      link: ({ children, mark }) => {
        return mark.blank ? (
          <a
            style={{ color: 'inherit' }}
            href={mark.href}
            target='_blank'
            rel='noopener noreferrer'>
            {children}
            <i
              className='fas fa-external-link-alt'
              style={{ marginLeft: '.5rem' }}></i>
          </a>
        ) : (
          <a style={{ color: 'inherit' }} href={mark.href}>
            {children}
          </a>
        );
      },
      arb: ({ children }) => {
        return <span dir='rtl'>{children}</span>;
      },
    },
    types: {
      code: (props) => {
        const { code, language } = props.node;
        if (!code) return null;
        return (
          <SyntaxHighlighter
            className='post_code'
            language={`${language}`}
            style={monokaiSublime}>
            {code}
          </SyntaxHighlighter>
        );
      },
      table: (props) => {
        return (
          <>
            <table className='post_table'>
              <thead>
                <tr>
                  {props.node.rows[0].cells.map((cell, index1) => (
                    <th key={index1}>{cell}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {props.node.rows.slice(1).map((row, index2) => (
                  <tr key={index2} className={index2 % 2 ? 'swap_color' : null}>
                    {row.cells.map((cell, index3) => (
                      <td key={index3}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        );
      },
      movie: ({ node }) => {
        return (
          <>
            <div className='movie'>
              {/* {JSON.stringify(node)} */}
              <div className='top_row'>
                <img
                  src={`https://cdn.sanity.io/images/5latcj86/production/${replaceLastDot(
                    node.poster.asset._ref
                  )}`}
                  alt={node.title}
                />
                <div className='movie_text'>
                  <div className='title_genres'>
                    <h3 className='movie_title'>{node.title}</h3>
                    <div className='genres'>
                      {node.genres.map((genre) => {
                        return (
                          <span key={genre} className='genre'>
                            {genre}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  <div className='ratings'>
                    <p>
                      <strong>Duration: </strong>
                      {node.duration} mins
                    </p>
                    <hr />
                    <p>
                      <strong>IMDb: </strong>
                      {node.imdbRating}/10
                    </p>
                    <p>
                      <strong>Rotten Tomatoes: </strong> {node.rottenRating}%
                    </p>
                    <p>
                      <strong>Letterboxd: </strong> {node.letterboxdRating}/5
                    </p>
                    <p>
                      <strong>My Rating: </strong> {node.myLetterboxdRating}/5
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p dir='rtl' className='brief'>
              {node.brief}
            </p>
          </>
        );
      },
    },
  };

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"]{
        title,
        subtitle,
        body,
        date,
          mainImage{
            asset->{
              _id,
              url
            }
          }
        }`
      )
      .then((data) => {
        setPost(data[0]);
        document.title = `${data[0].title}`;
        setLoading(false);
      });
  }, [slug]);

  return (
    <>
      {loading ? (
        <div className='loading'>
          <BeatLoader size={15} sizeUnit={'px'} margin={5} color={'#E4B05E'} />
        </div>
      ) : (
        <section className='post_content'>
          <div className='post_header'>
            {post.mainImage && post.mainImage.asset && (
              <img
                className='post_image'
                src={post.mainImage.asset.url}
                alt={post.title}
              />
            )}
            <div className='post_header_text'>
              <h1 className='post_title'>{post.title}</h1>
              <h1 className='post_subtitle'>{post.subtitle}</h1>
            </div>
          </div>
          {arabicChars.test(post.title) ? (
            <div className='post_body' style={{ direction: 'rtl' }}>
              <BlockContent
                blocks={post.body}
                projectId='5latcj86'
                dataset='production'
                serializers={serializers}
              />
            </div>
          ) : (
            <div className='post_body' style={{ direction: 'ltr' }}>
              <BlockContent
                blocks={post.body}
                projectId='5latcj86'
                dataset='production'
                serializers={serializers}
              />
            </div>
          )}
          <Disqus.DiscussionEmbed
            shortname={disqusShortname}
            config={{
              url: `https://omar45.com/blog/${slug}`,
              identifier: slug,
              title: post.title,
            }}
          />
        </section>
      )}
    </>
  );
};

export default SinglePost;

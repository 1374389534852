import './Project.css';

import Tag from './Tag';

const Project = (props) => {
  // const [modalOpen, setModalOpen] = useState(false);
  // const toggleModal = () => setModalOpen(!modalOpen);

  const {
    title = 'title',
    image = 'https://res.cloudinary.com/omar45/image/upload/v1627762115/projects/placeholder.png',
    desc = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia, perferendis?',
    link = 'https://www.omar45.com/',
    tags = ['react', 'redux'],
  } = props;

  // TODO
  const handleClick = () => {
    window.open(link, '_blank');
  };

  // useEffect(() => {
  //   const handleKeyDown = (e) => {
  //     if (e.key === 'Escape') {
  //       setModalOpen(false);
  //     }
  //   };
  //   window.addEventListener('keydown', handleKeyDown);
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 1000,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 4500,
  //   arrows: true,
  // };

  return (
    <div className='project_wrapper' onClick={handleClick}>
      <img src={image} alt={title} />
      <div className='project_text'>
        <h2>{title}</h2>
        <p>{desc}</p>
        <div className='tags_wrapper'>
          {tags.map((tag) => (
            <Tag className='tag' text={tag} key={tag} />
          ))}
        </div>
        {/* <button className='more' onClick={toggleModal}>
          See more <i class='fas fa-angle-right'></i>
        </button> */}
      </div>
      {/*modalOpen && (
        <div className='modal'>
          <div className='overlay' onClick={toggleModal}></div>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2>{title}</h2>
              <button onClick={toggleModal}>
                <i className='fas fa-times' />
              </button>
            </div>
            <div className='modal-body'>
              <Slider {...settings}>
                <img src={image} alt='image_1' />
                <img src={image} alt='image_1' />
              </Slider>
              <div className='modal-text'>
                <p>{desc}</p>
              </div>
            </div>
          </div>
        </div>
      )*/}
    </div>
  );
};

export default Project;

import './Tag.css';

const Tag = ({ text }) => {
  return (
    <span className='tag'>
      <p>{text}</p>
    </span>
  );
};

export default Tag;

import './Projects.css';

import { useEffect, useState } from 'react';

import GridLoader from 'react-spinners/GridLoader';
import Project from '../Project';
import firebase from '../../firebase';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMaterials = async () => {
    const db = firebase.firestore();
    const data = await db.collectionGroup('projects').orderBy('order').get();
    setProjects(data.docs.map((doc) => doc.data()));
    setTimeout(() => setIsLoading(false), 100);
  };

  useEffect(() => {
    document.title = 'Projects | Omar AbdulRahman';
    fetchMaterials();
  }, []);

  return (
    <div className='projects_wrapper'>
      {isLoading ? (
        <GridLoader
          css={'margin: 5rem auto; margin-bottom: 0'}
          sizeUnit={'px'}
          size={20}
          margin={5}
          color={'#E4B05E'}
        />
      ) : (
        projects.map((project) => (
          <Project
            key={project.desc.substr(10, 30)}
            title={project.title}
            image={project.image}
            desc={project.desc}
            link={project.link}
            tags={project.tags}
          />
        ))
      )}
      {/* {!isLoading && (
        <h2 className='find_more'>
          You can find more projects at my{' '}
          <a
            href='https://github.com/omarr45/'
            target='_blank'
            rel='noreferrer noopener'>
            GitHub account
          </a>
        </h2>
      )} */}
    </div>
  );
};

export default Projects;
